<template>
  <div v-loading='loading' class="headerbox">
    <img alt="" src="../assets/img/logo.png"/>
    <div v-if="show" class="center">
      <el-menu :default-active="activeIndex" active-text-color="#19CBBF" class="el-menu-demo" mode="horizontal" router>
        <el-menu-item index="/" @click="empty">Converting Order</el-menu-item>
        <el-submenu index="set">
          <template slot="title">Settings</template>
          <el-menu-item :disabled="api_id==''" index="setting">System setting</el-menu-item>
          <el-menu-item index="logList">Operation Log</el-menu-item>
          <el-menu-item index="user">User</el-menu-item>
          <el-menu-item index="api">DEAR API</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="right">
      <el-select v-model="api_id" filterable placeholder="Please select" remote size="small" @change="change">
        <div class="selebtn">
          <el-button type="text" @click.native.prevent="apishow=true">Connect DEAR</el-button>
        </div>
        <el-option v-for="item in options" :key="item.id" :label="item.company" :value="item.id">
        </el-option>
      </el-select>

      <el-dropdown class="logobox" trigger="click">
        <div class="logo">
          <div class="imgsbox">
            <img class="user-avatar" src="../assets/img/smile.svg">
          </div>
          <i class="el-icon-caret-bottom"/>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="sendshow=true">
            <span>Change Password</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span>Log Out</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog :visible.sync="sendshow" title="Change Password" width="30%">
      <el-form ref="sendform" :model="sendform" :rules="sendrules" class="formbox">
        <el-form-item prop="email">
          <span class="form_tit">Mailing Address*</span>
          <el-input v-model="sendform.email" clearable></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button :loading="loadinga" type="primary" @click.native.prevent="sendPasswordEmail">Send Out</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="apishow" title="Connect DEAR" width="30%">
      <el-form ref="form" :model="form" :rules="rules" class="formbox">
        <el-form-item prop="api_account">
          <span class="form_tit">API Account*</span>
          <el-input v-model="form.api_account" clearable></el-input>
        </el-form-item>
        <el-form-item prop="api_key">
          <span class="form_tit">API Key*</span>
          <el-input v-model="form.api_key" clearable></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button :loading='btn_loading' type="primary" @click.native.prevent="onSubmit">Confirm</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {removeToken} from "@/utils/auth";

export default {
  components: {},
  data() {
    return {
      activeIndex: "/",
      api: "",
      options: [],
      show: true,
      loading: false,
      // 修改密码
      sendshow: false,
      sendform: {
        email: "",
      },
      sendrules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: 'Please Enter Email Address',
          },
        ],
      },
      loadinga: false,

      // 添加
      apishow: false,
      btn_loading: false,
      form: {
        api_account: '',
        api_key: '',
      },
      rules: {
        api_account: [
          {required: true, trigger: "blur", message: 'Please enter'}
        ],
        api_key: [
          {required: true, trigger: "blur", message: 'Please enter'}
        ],
      },
    };
  },
  computed: {
    api_id: {
      get() {
        return this.$store.state.user.api_id;
      },
      set(data) {
        this.$store.dispatch("change_API_ID", data);
      },
    },
  },
  watch: {
    api_id: {
      handler(data) {
        if (data) {
          this.getinfo(data)
        }
      },
      deep: true,
      immediate: true
    },
    $route: {
      handler(data) {
        sessionStorage.setItem("activeIndex", data.name);
        this.activeIndex = data.name;
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    empty() {
      sessionStorage.setItem('btn_num', '0')
    },
    getlist() {
      this.$post({
        url: "/app/member_api/apiList",
        data: {
          page: 1,
          page_size: 1000,
        },
        success: (res) => {
          this.options = res.data.data;
          if (this.options.length && !this.api_id) {
            this.api_id = this.options[0].id;
            this.$store.dispatch("change_API_ID", this.api_id);
          }
        },
        tip: () => {
        },
      });
    },
    getinfo(api_id) {
      this.$post({
        url: "/app/member/getRules",
        data: {api_id},
        success: (res) => {
          this.$store.dispatch("set_ROLES", res.data.rule_names);
        },
        tip: () => {
        },
      });
    },
    change() {
      const {name} = this.$route
      this.$nextTick(() => {
        this.$router.replace({
          path: '/redirect',
          query: {
            redirect: name
          }
        })
      })
    },
    // 退出登录
    logout() {
      this.$confirm(`Are you sure you want to log out?`, 'Tips', {
        confirmButtonText: `Confirm`,
        cancelButtonText: `Cancel`,
        type: 'warning'
      }).then(() => {
        this.$post({
          url: "/app/member/logout",
          success: (res) => {
            removeToken()
            sessionStorage.removeItem('vuex')
            this.$router.replace('/login')
            this.$message({
              type: 'success',
              message: 'Log out successfully'
            });
          },
          tip: () => {
          },
        })
      }).catch(() => {
      });
    },
    // 修改密码
    sendPasswordEmail() {
      this.$refs.sendform.validate((valid) => {
        if (valid) {
          this.loadinga = true;
          this.$post({
            url: "/app/auth/sendPasswordEmail",
            data: this.sendform,
            success: () => {
              this.$message({
                message: 'Sent successfully',
                type: "success",
              });
              setTimeout(() => {
                this.sendshow = false;
                this.loadinga = false;
                location.reload();
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loadinga = false;
              }, 1500);
            },
          });
        }
      });
    },
    // 添加
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btn_loading = true
          this.$post({
            url: "/app/member_api/bindApi",
            data: this.form,
            success: (res) => {
              this.$message({
                message: 'success',
                type: 'success'
              });
              this.apishow = false
              setTimeout(() => {
                this.getlist()
                this.btn_loading = false
                location.reload();
              }, 1000);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
          })
        }
      });
    },
  },
  created() {
    this.getlist();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-header[data-v-fae5bece] {
  padding: 0;
}

.headerbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;

  > img {
    width: 100px;
    height: 40px;
    object-fit: cover;
  }

  .center {
    flex: 1;
    padding: 0 30px;

    /deep/ .el-menu-demo {
      flex: 1;

      .el-menu-item {
        padding: 0 7px;
      }
    }
  }

  .right {
    width: 16%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > img {
      width: 25px;
      height: 25px;
      margin-left: 40px;
    }

    .logobox {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .logo {
        display: flex;
        cursor: pointer;

        .imgsbox {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #000;
          border-radius: 50%;
          margin-right: 3px;
        }

        /deep/ .el-icon-caret-bottom {
          display: flex;
          align-items: flex-end;
          padding-bottom: 10px;
        }
      }
    }
  }

  .formbox {
    width: 480px;
    padding: 10px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /deep/ .el-form-item {
      width: 100%;
      margin-bottom: 30px !important;

      .el-input {
        .el-input__inner {
          height: 50px;
        }
      }

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color !important;
          border-radius: 5px;
          border-color: $color;
          font-size: 16px;
          font-weight: 550;
          color: #fff !important;
        }
      }
    }
  }
}

.selebtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
