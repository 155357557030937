<template>
  <el-container class="bodybox">
    <el-header>
      <Header/>
    </el-header>
    <el-main class="mainbox">
      <div v-if="!show" v-loading="loading" style="width:100%;height:200px"></div>
      <router-view v-if="show"/>
    </el-main>
  </el-container>
</template>
<script>
import Header from "../components/header"
import {mapGetters} from 'vuex';

export default {
  components: {Header},
  computed: {
    ...mapGetters(['api_id', 'roles']),
  },
  data() {
    return {
      show: false,
      loading: true
    }
  },
  watch: {
    api_id: {
      handler(data) {
        if (data) {
          this.showpage()
        }
      },
      deep: true,
      immediate: true
    },
    roles: {
      handler(data) {
        if (data) {
          this.showpage()
        }
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    showpage() {
      this.show = false
      this.loading = true
      setTimeout(() => {
        this.show = true
        this.loading = false
      }, 200);
    }
  },
}
</script>
<style lang="scss" scoped>
.bodybox {
  width: 100%;
  min-height: 100vh;
  min-width: 1200px;
  background: #f0f2f4;
}

/deep/ .el-header {
  width: 100%;
  min-height: 50px !important;
  height: auto !important;
  background: #ffffff;
  border-bottom: 1px solid #babdcd;
}

/deep/ .el-main {
  width: 100%;
  height: 90%;
  padding: 0;
}

/deep/ .el-breadcrumb__inner {
  font-size: 16px;
  font-weight: 400;
  color: #717988;
}

/deep/ .is-link {
  color: #babdcd;
}
</style>>
